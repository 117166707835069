import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SessionSelection.css';

const SessionSelection = () => {
    const navigate = useNavigate();

    const goToDashboard = () => {
        navigate('/Dashboard');
    };

    const goToFilePerformance = () => {
        navigate('/FilePerformance'); 
    };

    const goToRuntimeAnalysis = () => {
        navigate('/RuntimePerformance'); 
    };
    
    const goToVoipCost = () => {
        navigate('/VoipCost'); 
    };
    const goToClientReport = () => {
        navigate('/ClientReport'); 
    };
    const goToThreeWayReport = () => {
        navigate('/ThreeWayReport'); 
    };

    return (
        <div className="session-selection-container">
            <h1>Select Section</h1>
            <div className="card-container">
                <div className="card" onClick={goToDashboard}>
                    <h2>Agent Performance Section</h2>
                    <p>Analyze agent performance metrics and reports.</p>
                </div>
                <div className="card" onClick={goToFilePerformance}>
                    <h2>Files Performance Section</h2>
                    <p>Analyse and manage file performance data.</p>
                </div>
                <div className="card" onClick={goToRuntimeAnalysis}>
                    <h2>Runtime Analysis Section</h2>
                    <p>Analyse the files, how they perform on each run</p>
                </div>
                <div className="card" onClick={goToVoipCost}>
                    <h2>VoIP Cost</h2>
                    <p>Check the VoIP Cost</p>
                </div>
                <div className="card" onClick={goToClientReport}>
                    <h2>Client Report</h2>
                    <p>Client Report analysis and along with how our data performs for client, everything</p>
                </div>
                <div className="card" onClick={goToThreeWayReport}>
                    <h2>Threeway Report</h2>
                    <p>Threeway Report for analysis</p>
                </div>
            </div>
        </div>
    );
};

export default SessionSelection;
