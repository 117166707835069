// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-selection-container {
    text-align: center;
    padding: 20px;
}

.card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 20px;
    margin-top: 20px;
}

.card {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.3s ease;
    background-color: #fff;
}

.card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px); /* Adds a subtle lift effect */
}

.card h2 {
    margin-bottom: 10px;
    font-size: 18px;
}

.card p {
    color: #666;
    font-size: 14px;
}

.restricted-card {
    background-color: #ffcccc; /* Light red to indicate restricted access */
    border: 2px solid #ff0000;
}

/* Responsive Design */
@media (max-width: 768px) {
    .card-container {
        flex-direction: column; /* Stack cards vertically on smaller screens */
        align-items: center;
    }

    .card {
        width: 90%; /* Allow cards to take most of the width on smaller screens */
    }
}
`, "",{"version":3,"sources":["webpack://./src/SessionSelection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe,EAAE,uCAAuC;IACxD,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,wCAAwC;IACxC,eAAe;IACf,qDAAqD;IACrD,sBAAsB;AAC1B;;AAEA;IACI,yCAAyC;IACzC,2BAA2B,EAAE,8BAA8B;AAC/D;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,yBAAyB,EAAE,4CAA4C;IACvE,yBAAyB;AAC7B;;AAEA,sBAAsB;AACtB;IACI;QACI,sBAAsB,EAAE,8CAA8C;QACtE,mBAAmB;IACvB;;IAEA;QACI,UAAU,EAAE,6DAA6D;IAC7E;AACJ","sourcesContent":[".session-selection-container {\n    text-align: center;\n    padding: 20px;\n}\n\n.card-container {\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap; /* Allows wrapping on smaller screens */\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.card {\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 10px;\n    width: 250px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    cursor: pointer;\n    transition: transform 0.2s ease, box-shadow 0.3s ease;\n    background-color: #fff;\n}\n\n.card:hover {\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n    transform: translateY(-3px); /* Adds a subtle lift effect */\n}\n\n.card h2 {\n    margin-bottom: 10px;\n    font-size: 18px;\n}\n\n.card p {\n    color: #666;\n    font-size: 14px;\n}\n\n.restricted-card {\n    background-color: #ffcccc; /* Light red to indicate restricted access */\n    border: 2px solid #ff0000;\n}\n\n/* Responsive Design */\n@media (max-width: 768px) {\n    .card-container {\n        flex-direction: column; /* Stack cards vertically on smaller screens */\n        align-items: center;\n    }\n\n    .card {\n        width: 90%; /* Allow cards to take most of the width on smaller screens */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
