// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-card {
    margin: 40px auto;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #ffffff;
}

.form-title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
}

.submit-button {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #1976d2; /* Primary Blue */
    color: #fff;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #135ba1; /* Darker Blue */
}

.reset-button {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid #d32f2f;
    color: #d32f2f;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.reset-button:hover {
    background-color: #d32f2f;
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/RetellClientPosting.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,2CAA2C;IAC3C,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,yBAAyB,EAAE,iBAAiB;IAC5C,WAAW;IACX,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB,EAAE,gBAAgB;AAC/C;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;IACzB,cAAc;IACd,uDAAuD;AAC3D;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".form-card {\n    margin: 40px auto;\n    padding: 20px;\n    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n    border-radius: 10px;\n    background-color: #ffffff;\n}\n\n.form-title {\n    text-align: center;\n    margin-bottom: 20px;\n    font-weight: bold;\n    color: #333;\n}\n\n.submit-button {\n    padding: 12px 20px;\n    font-size: 16px;\n    font-weight: bold;\n    background-color: #1976d2; /* Primary Blue */\n    color: #fff;\n    transition: background-color 0.3s ease;\n}\n\n.submit-button:hover {\n    background-color: #135ba1; /* Darker Blue */\n}\n\n.reset-button {\n    padding: 12px 20px;\n    font-size: 16px;\n    font-weight: bold;\n    border: 2px solid #d32f2f;\n    color: #d32f2f;\n    transition: background-color 0.3s ease, color 0.3s ease;\n}\n\n.reset-button:hover {\n    background-color: #d32f2f;\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
